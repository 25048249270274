import React, { useState } from "react"
import "../styles/global.css"
import Album from "../components/album"

// markup
const IndexPage = () => {

  return(
    <main>
      <header><h2 class="heading-2">Steve Clark's Music</h2></header>
      <Album />
    </main>
  )
}

export default IndexPage
