import React, { useState } from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const Album = () => {
  const data = useStaticQuery(graphql`
    query albums {
      allWpAlbum {
        nodes {
          title
          uri
          albumACF {
            coverImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 375, height: 250)
                }
              }
            }
          }
        }
      }
    }
  `)

  // const albums = [
  //   {
  //     name: "Album 1",
  //     author: 'Steve Clark',
  //     img: '/cover.jpg',
  //   },
  //   {
  //     name: "Album 2",
  //     author: 'Steve Clark',
  //     img: '/cover.jpg',
  //   }
  // ];
  return (
    <div className="album-list">
      <div className="play-list" >
        {data.allWpAlbum.nodes.map((album, key=0) =>
        <AniLink className="track" key={key} swipe direction="left" exit="bottom" entryOffset={180} to={album.uri} duration={0.4}>
           <GatsbyImage
                  image={album.albumACF.coverImage.localFile.childImageSharp.gatsbyImageData}
                  alt={album.albumACF.coverImage.altText}
                  className="track-img"
                  />
           <div className="track-discr">
             <span className="track-name">{album.title}</span>
             <span className="track-author">Steve Clark</span>
           </div>
        </AniLink>
        )}
      </div>
    </div>
  )
}

export default Album
